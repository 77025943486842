import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { durationIcon, filterIcon } from "../../../../images";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import SelectSeats from "./SelectSeats";
import { availableTrips } from "../../../../urls";
import API from "../../../../components/api2";
import loaderGif from "../../../../gif/buffer.gif";

const styles = {
  card: {
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "19px",
    padding: "1rem",
    marginTop: "1rem",
  },
  title: {
    fontWeight: "600",
    color: "#023685",
    fontSize: "15px",
  },
  text: {
    fontSize: "14px",
    fontWeight: "500",
  },
  priceText: {
    fontSize: "15px",
    fontWeight: "600",
  },
  seatsInfo: {
    fontSize: "14px",
    fontWeight: "500",
    color: "red",
  },
  singleSeats: {
    fontSize: "15px",
    fontWeight: "500",
  },
  border: {
    borderLeft: "1.5px solid #A3A3A3",
    borderRight: "1.5px solid #A3A3A3",
  },
  textCenter: {
    textAlign: "center",
  },
  container: {
    height: "calc(100vh - 360px)",
    overflowY: "auto",
  },
  rowMargin: {
    marginTop: "1rem",
  },
};

const AvailableTrips = ({
  trips,
  departureDay,
  setShowSelectSeats,
  showSelectSeats,
  sourceId,
  destinationId,
}) => {
  const [displayData, setDisplayData] = useState(trips);
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [proceedToPay, setProceedToPay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({
    sleeper_filter: "",
    duration_sort_order: "",
    ac_filter: "",
    sort_order: "",
  });

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    applyFilters({ ...filters, [key]: value });
  };

  const applyFilters = (newFilters) => {
    setLoader(true);
    API({
      ...availableTrips,
      body: {
        ...newFilters,
        date_of_journey: departureDay,
        source_id: sourceId,
        destination_id: destinationId,
      },
      onSuccess: (res) => {
        setLoader(false);
        setDisplayData(res?.data?.data?.availableTrips || []);
      },
      onError: (error) => {
        setLoader(false);
        console.error("Error fetching available trips:", error);
      },
    });
  };

  const clearFilters = () => {
    const resetFilters = {
      sleeper_filter: "",
      duration_sort_order: "",
      ac_filter: "",
      sort_order: "",
    };
    setFilters(resetFilters);
    applyFilters(resetFilters);
  };

  const formatDepartureDay = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    return `${day} ${month}`;
  };

  // handle select seats
  const handleSelectSeats = (tripId, travels, busType) => {
    setSelectedTripId({ tripId, travels, busType });
    setShowSelectSeats(true);
  };

  if (showSelectSeats && selectedTripId) {
    return (
      <SelectSeats
        tripId={selectedTripId}
        trips={trips}
        setProceedToPay={setProceedToPay}
        proceedToPay={proceedToPay}
        sourceId={sourceId}
        destinationId={destinationId}
        setShowSelectSeats={setShowSelectSeats}
        showSelectSeats={showSelectSeats}
      />
    );
  }

  return (
    <div className="row mt-2">
      <div className="col-3">
        <Accordion className="custom-accordion-filter" defaultActiveKey="0">
          <Accordion.Item
            eventKey="0"
            style={{ backgroundColor: "transparent" }}
          >
            <Accordion.Header className="custom-accordion-filter">
              <img
                style={{ width: "18px" }}
                className="me-2"
                src={filterIcon}
                alt="filter"
              />
              {"Filters"}
              <MdKeyboardDoubleArrowDown
                className="me-5"
                style={{ fontSize: "18px" }}
              />
              <button
                onClick={clearFilters}
                style={{
                  backgroundColor: "#023685",
                  color: "white",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
                className="border-0 ms-4"
              >
                Clear all
              </button>
            </Accordion.Header>
            <Accordion.Body
              style={{
                height: "calc(100vh - 410px)",
                border: "1px solid #D9D9D9",
                borderRadius: "5px",
              }}
              className="overflow-y-auto"
            >
              <div className="p-3">
                <h2 style={{ fontSize: "15px", fontWeight: "600" }}>Filters</h2>
                <div className="mb-3">
                  <h2 style={{ fontSize: "15px", fontWeight: "600" }}>
                    Sleeper Filter
                  </h2>
                  <label>
                    <input
                      type="radio"
                      name="sleeper_filter"
                      value="true"
                      className="me-2"
                      checked={filters.sleeper_filter === "true"}
                      onChange={(e) =>
                        handleFilterChange("sleeper_filter", e.target.value)
                      }
                    />
                    Sleeper
                  </label>
                  <label className="ms-3">
                    <input
                      type="radio"
                      name="sleeper_filter"
                      value="false"
                      className="me-2"
                      checked={filters.sleeper_filter === "false"}
                      onChange={(e) =>
                        handleFilterChange("sleeper_filter", e.target.value)
                      }
                    />
                    Non-Sleeper
                  </label>
                </div>
                <div className="mb-3">
                  <h2 style={{ fontSize: "15px", fontWeight: "600" }}>
                    AC Filter
                  </h2>
                  <label>
                    <input
                      type="radio"
                      name="ac_filter"
                      value="ac"
                      className="me-2"
                      checked={filters.ac_filter === "ac"}
                      onChange={(e) =>
                        handleFilterChange("ac_filter", e.target.value)
                      }
                    />
                    AC
                  </label>
                  <label className="ms-3">
                    <input
                      type="radio"
                      name="ac_filter"
                      value="non-ac"
                      className="me-2"
                      checked={filters.ac_filter === "non-ac"}
                      onChange={(e) =>
                        handleFilterChange("ac_filter", e.target.value)
                      }
                    />
                    Non-AC
                  </label>
                </div>
                <div className="mb-3">
                  <h2 style={{ fontSize: "15px", fontWeight: "600" }}>
                    Duration Sort Order
                  </h2>
                  <label>
                    <select
                      className="form-select"
                      value={filters.duration_sort_order}
                      style={{
                        outline: "none",
                        boxShadow: "none",
                        borderColor: "inherit",
                      }}
                      onChange={(e) =>
                        handleFilterChange(
                          "duration_sort_order",
                          e.target.value
                        )
                      }
                    >
                      <option value="late_to_early">Late to Early</option>
                      <option value="early_to_late">Early to Late</option>
                    </select>
                  </label>
                </div>

                <div className="mb-3">
                  <h2 style={{ fontSize: "15px", fontWeight: "600" }}>
                    Sort Order
                  </h2>
                  <label>
                    <select
                      className="form-select"
                      value={filters.sort_order}
                      style={{
                        outline: "none",
                        boxShadow: "none",
                        borderColor: "inherit",
                      }}
                      onChange={(e) =>
                        handleFilterChange("sort_order", e.target.value)
                      }
                    >
                      <option value="high_to_low">High to Low</option>
                      <option value="low_to_high">Low to High</option>
                    </select>
                  </label>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="col-9 position-relative">
        {loader ? (
          <img
            src={loaderGif}
            alt="Loading..."
            style={{
              height: "60px",
              marginLeft: "45%",
              marginTop: "10%",
            }}
          />
        ) : (
          <div style={styles.container}>
            {displayData && displayData.length > 0 ? (
              displayData.map((trip, index) => {
                return (
                  <div key={index} style={styles.card}>
                    <h2 style={styles.title}>{trip.travels}</h2>
                    <div className="d-flex align-items-center justify-content-between">
                      <p
                        className="mb-0"
                        style={{ ...styles.text, width: "320px" }}
                      >
                        {trip.busType}
                      </p>
                      <div style={styles.textCenter}>
                        <p className="mb-0" style={styles.text}>
                          {trip?.departureTime}
                        </p>
                        <p className="mb-0" style={styles.text}>
                          {formatDepartureDay(departureDay)}
                        </p>
                      </div>
                      <div className="mx-2" style={styles.textCenter}>
                        <img src={durationIcon} alt="duration" />
                        <p
                          className="mb-0 mt-1"
                          style={{ ...styles.text, fontWeight: "700" }}
                        >
                          {trip.duration}
                        </p>
                      </div>
                      <p className="mb-0" style={styles.text}>
                        {trip?.arrivalTime}
                      </p>

                      <div
                        style={{ ...styles.textCenter, ...styles.border }}
                        className="px-3 mx-3"
                      >
                        <p className="mb-1" style={styles.seatsInfo}>
                          {trip.availableSingleSeat} single seats left
                        </p>
                        <p className="mb-0" style={styles.singleSeats}>
                          {trip.availableSeats} seats left
                        </p>
                      </div>
                      <div style={{ ...styles.textCenter }}>
                        <p className="mb-1" style={styles.priceText}>
                          ₹{" "}
                          {Array.isArray(trip.fares)
                            ? trip.fares.length > 0
                              ? Math.min(...trip.fares)
                              : "N/A"
                            : trip.fares || "N/A"}{" "}
                        </p>
                        <button
                          className="text-white border-0 px-3 py-1"
                          onClick={() =>
                            handleSelectSeats(
                              trip.id,
                              trip.travels,
                              trip.busType
                            )
                          }
                          style={{
                            backgroundColor: "#063E70",
                            borderRadius: "6px",
                            fontSize: "15px",
                          }}
                        >
                          Select Seats
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "#696666",
                }}
              >
                No trips available. Please try again later.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableTrips;
