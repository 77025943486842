import React, { useEffect, useState } from "react";
import API from "../../../../components/api2";
import Select from "react-select";
import { serviceCircle, success } from "../../../../images";
import { discoverAvdm, CaptureAvdm } from "../../../../utils/rd";
import axios from "axios";
import {
  aadharValidation,
  aepsBankList,
  aepsMerchantAuthenticity,
  aepsMerchAuthCheck,
  aepsWithdraw,
} from "../../../../urls";
import Swal from "sweetalert2";
import { FcApproval } from "react-icons/fc";
import { ImCross } from "react-icons/im";
import { FiRefreshCcw } from "react-icons/fi";
import PipeSelector from "../PipeSelector";

const AepsWithdrawal = () => {
  const [formData, setFormData] = useState({
    aadhar: "",
    mobile_number: "",
    nbi: "",
    device_name: "",
    amount: "",
  });

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [showAmountMessage, setShowAmountMessage] = useState(false);
  const [showAadharMessage, setShowAadharMessage] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);
  const [rd, setRD] = useState(false);
  const [fingerData, setFingerData] = useState();
  const [data, setData] = useState({});
  const [bankList, setBankList] = useState([]);
  const [withdrawRes, setWithdrawRes] = useState({});
  const [isMerchantAuthRequired, setIsMerchantAuthRequired] = useState(false);
  const [merchantMobileNumber, setMerchantMobileNumber] = useState("");
  const [merchAuthTxnId, setMerchAuthTxnId] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRotating, setIsRotating] = useState(false);
  const [selectedPipe, setSelectedPipe] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePipeSelect = (pipe) => {
    setSelectedPipe(pipe);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "aadhar") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 12) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
        if (formattedValue.length === 12) {
          handleAadharValidation(formattedValue);
        } else {
          setShowAadharMessage(
            "Minimum length of Aadhar number should be 12 digits."
          );
        }
      }
    } else if (name === "mobile_number") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else if (name === "amount") {
      const amount = parseInt(value, 10);
      if (!isNaN(amount)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: amount,
        }));
        if (amount < 100 || amount > 10000 || amount % 50 !== 0) {
          setShowAmountMessage(true);
        } else {
          setShowAmountMessage(false);
        }
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: "",
        }));
        setShowAmountMessage(true);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      nbi: selectedOption.value,
    }));
  };

  const handleConsentChange = (e) => {
    setConsentGiven(e.target.checked);
  };

  // validation
  const checkAllFieldsFilled = () => {
    const { aadhar, nbi, amount, device_name, mobile_number } = formData;
    if (
      aadhar &&
      mobile_number &&
      // device_name !== "No device found" &&
      amount &&
      nbi &&
      consentGiven
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  };

  useEffect(() => {
    checkAllFieldsFilled();
  }, [formData, consentGiven]);

  // finger scan
  navigator.geolocation.getCurrentPosition(function (position) {
    setData((prev) => ({
      ...prev,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await discoverAvdm();
        const trimmedDeviceName = data?.deviceInfo?.includes("Mantra MFS110")
          ? "Mantra MFS110"
          : data?.deviceInfo || "No device found";

        const deviceNameColor =
          trimmedDeviceName === "No device found" ? "red" : "white";

        setRD(data);
        setFormData((prevState) => ({
          ...prevState,
          device_name: trimmedDeviceName,
          device_name_color: deviceNameColor,
        }));
      } catch (error) {
        console.error("Error discovering device:", error);
        setFormData((prevState) => ({
          ...prevState,
          device_name: "No device found",
          device_name_color: "red",
        }));
      }
    };

    setFormData((prevState) => ({
      ...prevState,
      device_name: "No device found",
      device_name_color: "red",
    }));

    fetchData();
  }, [refreshKey]);

  const captureFingerData = (e) => {
    if (e) e.preventDefault();
    if (![undefined, false, ""].includes(rd)) {
      CaptureAvdm(rd).then((xml) => {
        setFingerData(xml);
      });
    }
  };
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        const ipAddress = response.data.ip;
        setData((prev) => ({
          ...prev,
          ip: ipAddress,
        }));
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  // banklist
  useEffect(() => {
    API({
      ...aepsBankList,
      onSuccess: (res) => {
        const data = res.data.data;
        const bankOptions = data.map((bank) => ({
          value: bank.iinno,
          label: bank.bankName,
        }));
        setBankList(bankOptions);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });
  }, []);

  // handle aadhar validation
  const handleAadharValidation = (aadhar) => {
    // e.preventDefault();
    if (aadhar.length !== 12) return false;

    API({
      ...aadharValidation,
      body: {
        aadhar: aadhar,
      },
      onSuccess: (res) => {
        console.log(res, "addavalid");

        if (res.data.data.aadhar_validation) {
          setShowAadharMessage(false);
        } else {
          setShowAadharMessage("The Aadhar number is invalid.");
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // handle continue
  const handleContinue = (e) => {
    e.preventDefault();
    setLoading(true);

    API({
      ...aepsWithdraw,
      body: {
        ...formData,
        fingerdata: fingerData,
        pipe: selectedPipe.value,
        latitude: data.latitude,
        longitude: data.longitude,
        auth_txn_id: merchAuthTxnId,
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          setWithdrawRes(res.data.data);
          setShowWithdrawConfirm(true);
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setFingerData(null);
      },
    });
  };

  const isAmountValid =
    formData.amount >= 100 &&
    formData.amount <= 10000 &&
    formData.amount % 50 === 0;

  // handle print
  const handlePrint = () => {
    const printContents = document.getElementById("print-section").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  // handle reset
  const resetForm = () => {
    setFormData({
      aadhar: "",
      mobile_number: "",
      nbi: "",
      device_name: "",
      amount: "",
    });
    setConsentGiven(false);
    setFingerData(null);
    setShowWithdrawConfirm(false);
    setShowAmountMessage(false);
  };

  // handle refresh
  const handleRefresh = () => {
    setIsRotating(true);
    setRefreshKey((prevKey) => prevKey + 1);
    setTimeout(() => setIsRotating(false), 1000);
  };

  // merchant auth check
  useEffect(() => {
    if (rd)
      API({
        ...aepsMerchAuthCheck,
        params: {
          pipe: selectedPipe?.value,
        },
        onSuccess: (res) => {
          if (res.data.data.merchant_auth_required) {
            setIsMerchantAuthRequired(true);
            setMerchantMobileNumber(res.data.data.merchant_mobile_number);
            Swal.fire({
              title: `${res.data.message}`,
            }).then(() => {
              captureFingerData();
            });
          } else {
            setIsMerchantAuthRequired(false);
          }
        },
        onError: (error) => {
          // Swal.fire({
          //   icon: "error",
          //   title: `${error?.response?.data?.message}`,
          // });
          console.log("error", error);
        },
      });
  }, [rd, selectedPipe]);

  useEffect(() => {
    if (isMerchantAuthRequired && fingerData !== undefined) {
      Swal.fire({
        title: "Enter your Aadhaar number",
        input: "text",
        inputAttributes: {
          maxlength: 12,
          autocapitalize: "off",
          autocorrect: "off",
        },
        inputValue: "",
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        preConfirm: (aadhaarNumber) => {
          if (
            !aadhaarNumber ||
            aadhaarNumber.length !== 12 ||
            isNaN(aadhaarNumber)
          ) {
            Swal.showValidationMessage(
              "Please enter a valid 12-digit Aadhaar number"
            );
            return false;
          }
          return aadhaarNumber;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const aadhaarNumber = result.value;

          API({
            ...aepsMerchantAuthenticity,
            body: {
              aadhar: aadhaarNumber,
              mobile_number: merchantMobileNumber,
              fingerdata: fingerData,
              latitude: data.latitude,
              longitude: data.longitude,
              pipe: selectedPipe.value,
            },
            onSuccess: (res) => {
              setMerchAuthTxnId(res.data.data.MerAuthTxnId);
              setIsMerchantAuthRequired(false);
              setFingerData();
              Swal.fire({
                title: `${res.data.message}`,
              });
            },
            onError: (error) => {
              Swal.fire({
                icon: "error",
                title: `${error.response.data.message}`,
                message: "Please try again with other bank",
              });
            },
          });
        }
      });
    }
  }, [fingerData]);

  return (
    <>
      {showWithdrawConfirm ? (
        //  withdraw status
        <>
          <div className="mx-5 mt-4 px-2 login_text">
            <div className="row mb-2">
              {/* witdhraw form  */}
              <div className="col-7">
                <div
                  className="position-relative pb-2"
                  style={{
                    backgroundColor: "rgba(25, 66, 128, 1)",
                    borderRadius: "20px",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      width: "70px",
                    }}
                    src={serviceCircle}
                    alt="circle"
                  />
                  <div className="d-flex align-items-center p-3 mb-3">
                    {withdrawRes.status === true && (
                      <img src={success} alt="success" />
                    )}
                    <h2
                      className="mb-0 ms-3 mt-2"
                      style={{
                        fontSize: "28px",
                        color: withdrawRes.status
                          ? "rgba(20, 140, 67, 1)"
                          : "rgba(247, 62, 62, 1)",
                      }}
                    >
                      {withdrawRes.status
                        ? "Withdrawal Successful"
                        : withdrawRes.message}
                    </h2>
                  </div>
                  <div
                    style={{
                      borderBottom: "3px solid rgba(217, 217, 217, 0.19)",
                    }}
                  ></div>

                  {/* details  */}
                  <div id="print-section" className="mt-3">
                    {withdrawRes && (
                      <>
                        <div className="d-flex justify-content-between px-5 mt-2">
                          <div>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 login_text"
                            >
                              Client Reference Number
                            </p>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 mt-3 login_text"
                            >
                              Withdrawal Amount
                            </p>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 mt-3 login_text"
                            >
                              Balance Amount
                            </p>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 mt-3 login_text"
                            >
                              Bank RRN Number
                            </p>
                          </div>
                          <div>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 login_text"
                            >
                              {withdrawRes.clientrefno}
                            </p>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 login_text mt-3"
                            >
                              ₹ {withdrawRes.amount}
                            </p>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 login_text mt-3"
                            >
                              ₹ {withdrawRes.balanceamount}
                            </p>
                            <p
                              style={{ fontSize: "18px", color: "white" }}
                              className="mb-0 login_text mt-3"
                            >
                              {withdrawRes.bankrrn}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* buttons  */}
                  <div className="d-flex justify-content-between mx-5 mt-4 pb-3">
                    <div>
                      <button
                        onClick={handlePrint}
                        style={{
                          borderRadius: "10px",
                          fontWeight: "500",
                          border: "none",
                        }}
                        className="bg-white py-1 px-3 "
                      >
                        Print
                      </button>
                      <button
                        style={{
                          borderRadius: "10px",
                          fontWeight: "500",
                          border: "none",
                        }}
                        className="bg-white py-1 px-3 ms-3"
                        onClick={resetForm}
                      >
                        New Transaction
                      </button>
                    </div>
                    <button
                      style={{
                        borderRadius: "10px",
                        fontWeight: "500",
                        border: "none",
                      }}
                      className="bg-white py-1 px-3"
                      onClick={resetForm}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mx-5 mt-3 px-2">
          <div className="row mb-3">
            {/* withdrawal form  */}
            <div className="col-7">
              <div
                className="px-4 position-relative"
                style={{
                  backgroundColor: "rgba(25, 66, 128, 1)",
                  borderRadius: "20px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                    width: "120px",
                  }}
                  src={serviceCircle}
                  alt="circle"
                />

                <div className="mx-2 pt-2 pb-4 col-6">
                  <label
                    htmlFor="aadhar"
                    style={{
                      color: "#ADACAC",
                      transition: "all 0.4s ease-in-out",
                      fontSize: "14px",
                      opacity: formData.aadhar ? 1 : 0,
                      marginTop: "8px",
                    }}
                    className="ms-2 login_text mt-2"
                  >
                    Aadhar Number
                  </label>
                  <input
                    type="number"
                    id="aadhar"
                    name="aadhar"
                    value={formData.aadhar}
                    onChange={handleInputChange}
                    placeholder="Aadhar Number"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: formData.aadhar
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: "white",
                      borderRadius: "0px",
                      padding: "0px 8px",
                    }}
                    autoComplete="off"
                    className="login_text"
                    disabled={isMerchantAuthRequired}
                  />
                  {showAadharMessage && (
                    <div
                      className="fs_xl"
                      style={{
                        color: "yellow",
                        marginTop: "8px",
                        fontSize: "12px",
                      }}
                    >
                      {showAadharMessage}
                    </div>
                  )}
                  <label
                    htmlFor="mobile_number"
                    style={{
                      color: "#ADACAC",
                      transition: "all 0.4s ease-in-out",
                      fontSize: "14px",
                      opacity: formData.mobile_number ? 1 : 0,
                      paddingTop: "10px",
                    }}
                    className="ms-2 login_text mt-2"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    id="mobile_number"
                    name="mobile_number"
                    value={formData.mobile_number}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: formData.mobile_number
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: "white",
                      borderRadius: "0px",
                      padding: "0px 8px",
                    }}
                    autoComplete="off"
                    className="login_text"
                    disabled={isMerchantAuthRequired}
                  />
                  <label
                    htmlFor="nbi"
                    style={{
                      color: "#ADACAC",
                      transition: "all 0.4s ease-in-out",
                      fontSize: "14px",
                      opacity: formData.nbi ? 1 : 0,
                      paddingTop: "10px",
                    }}
                    className="ms-2 login_text mt-2"
                  >
                    Select Bank List
                  </label>
                  <Select
                    id="bank"
                    name="bank"
                    className="recharge_select login_text"
                    placeholder="Select Bank List"
                    value={bankList.find(
                      (option) => option.value === formData.nbi
                    )}
                    options={bankList}
                    onChange={handleSelectChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        borderWidth: "0 0 1.5px 0",
                        borderBottom: formData.nbi
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: formData.nbi ? "white" : "#ADACAC",
                        borderRadius: "0px",
                        boxShadow: "none",
                        "&:focus": {
                          outline: "none",
                          boxShadow: "none",
                        },
                        marginTop: "-8px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "white",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "white",
                      }),
                    }}
                    isDisabled={isMerchantAuthRequired}
                  />
                  <div className="d-flex align-items-end justify-content-between">
                    <div>
                      <label
                        htmlFor="device_name"
                        style={{
                          color: "#ADACAC",
                          transition: "all 0.4s ease-in-out",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                        className="ms-2 login_text mt-2"
                      >
                        Device Name
                      </label>
                      <input
                        type="text"
                        id="device_name"
                        name="device_name"
                        value={formData.device_name}
                        onChange={handleInputChange}
                        placeholder="Device Name"
                        style={{
                          backgroundColor: "transparent",
                          color: formData.device_name_color,
                          width: "100%",
                          borderRadius: "0px",
                          padding: "0px 8px",
                        }}
                        autoComplete="off"
                        className="login_text"
                        readOnly
                      />
                    </div>
                    <div>
                      <FiRefreshCcw
                        className={isRotating ? "rotating" : ""}
                        style={{
                          color: "white",
                          fontSize: "15px",
                          marginTop: "-4px",
                          cursor: "pointer",
                        }}
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                  <label
                    htmlFor="amount"
                    style={{
                      color: "#ADACAC",
                      transition: "all 0.4s ease-in-out",
                      fontSize: "14px",
                      opacity: formData.amount ? 1 : 0,
                      marginTop: "8px",
                    }}
                    className="ms-2 login_text"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: formData.amount
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: "white",
                      borderRadius: "0px",
                      padding: "0px 8px",
                    }}
                    autoComplete="off"
                    className="login_text"
                    disabled={isMerchantAuthRequired}
                  />
                  {showAmountMessage && (
                    <div
                      className="fs_xl"
                      style={{
                        color: "yellow",
                        marginTop: "8px",
                        fontSize: "12px",
                      }}
                    >
                      The amount of transaction should be between ₹ 100 to ₹
                      10000 only and in multiples of ₹ 50.
                    </div>
                  )}

                  <div style={{ width: "620px" }} className="mt-4 d-flex">
                    <input
                      type="checkbox"
                      id="consent"
                      name="consent"
                      checked={consentGiven}
                      onChange={handleConsentChange}
                      style={{
                        marginRight: "10px",
                      }}
                      disabled={isMerchantAuthRequired}
                    />
                    <label
                      htmlFor="consent"
                      className="fs_xl"
                      style={{ color: "white", fontSize: "13px" }}
                    >
                      I am providing my consent to PrayasPe Finance Private
                      Limited for using my Aadhaar number and fingerprint for
                      authorization of this transaction which is being initiated
                      by the PrayasPe Retailer for balance/enquiry/cash
                      withdrawal/mini statement.
                    </label>
                  </div>

                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    <button
                      className="px-4 py-2 login_text"
                      style={{
                        backgroundColor: fingerData ? "green" : "white",
                        color: fingerData ? "white" : "black",
                        borderRadius: "10px",
                        border: "none",
                        fontSize: "15px",
                        fontWeight: "500",
                        cursor:
                          allFieldsFilled && !loading && isAmountValid
                            ? "pointer"
                            : "not-allowed",
                        opacity:
                          allFieldsFilled && !loading && isAmountValid
                            ? 1
                            : 0.5,
                      }}
                      disabled={!allFieldsFilled || loading || !isAmountValid}
                      onClick={
                        rd
                          ? fingerData
                            ? handleContinue
                            : captureFingerData
                          : null
                      }
                    >
                      {loading
                        ? "Loading..."
                        : fingerData
                        ? "Continue"
                        : "Capture"}
                    </button>
                    <p
                      style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                      className="mb-0 text-white"
                    >
                      Merchant Authenticated{" "}
                      {isMerchantAuthRequired ? (
                        <ImCross style={{ fontSize: "12px", color: "red" }} />
                      ) : (
                        <FcApproval style={{ fontSize: "18px" }} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* transaction history  */}
            <div className="col-5">
              <PipeSelector onSelectBank={handlePipeSelect} />
              <div
                className="mt-4"
                style={{
                  boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
                  borderRadius: "20px",
                  border: "1px solid rgba(217, 217, 217, 1)",
                }}
              >
                <div
                  className="py-3"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "20px 20px 0px 0px",
                  }}
                >
                  <h3
                    className="mb-0 login_text"
                    style={{ fontSize: "14px", textAlign: "center" }}
                  >
                    Transaction History
                  </h3>
                </div>
                <div style={{ height: "160px" }} className="py-3">
                  <p
                    style={{ fontSize: "14px", textAlign: "center" }}
                    className="mb-0 login_text"
                  >
                    No Record Found
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AepsWithdrawal;
