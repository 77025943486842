import React, { useState, useEffect, useRef } from "react";
import {
  bankTransferIcon,
  fundTransferBenef,
  newLogo,
  serviceBackArrow,
  serviceCircle,
} from "../../../images";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import Select from "react-select";
import API from "../../../components/api2";
import { FiRefreshCcw } from "react-icons/fi";
import { FaClock } from "react-icons/fa";

import {
  deleteBeneficiary,
  dmtBankList,
  dmtPennyDrop,
  fetchBeneficiary,
  regNewBeneficiary,
  dmtTransact,
  transSummmary,
  dmtRefundOtp,
  dmtRefund,
  dmtIfscList,
  dmtStatus,
  dmtTransactOtp,
} from "../../../urls";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { FcApproval } from "react-icons/fc";
import { FaCheckCircle, FaSpinner, FaTimesCircle } from "react-icons/fa";
import loaderGif from "../../../gif/buffer.gif";

const AddBeneficiary = () => {
  const location = useLocation();
  const { mobile } = location.state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showPennyDrop, setShowPennyDrop] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [ifscList, setIfscList] = useState([]);
  const [rotatingIds, setRotatingIds] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState({
    beneficiary_name: "",
    bank_id: "",
    account_number: "",
    ifsccode: "",
    dob: "",
    address: "",
    pincode: "",
  });
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);
  const [transDetails, setTransDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermTrans, setSearchTermTrans] = useState("");
  const [showFundModal, setShowFundModal] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [upin, setUpin] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [isRotating, setIsRotating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateresp, setStateresp] = useState("");
  const [refId, setRefId] = useState("");

  // filter beneficiary
  const filteredBeneficiaries = beneficiaryDetails?.filter(
    (beneficiary) =>
      beneficiary.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      beneficiary.accno.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_number") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 10) {
        setBeneficiaryData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else if (name === "pincode") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 6) {
        setBeneficiaryData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else if (name === "beneficiary_name") {
      const formattedValue = value.replace(/[^a-zA-Z\s]/g, "");
      setBeneficiaryData((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    } else {
      setBeneficiaryData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setBeneficiaryData((prevData) => ({
      ...prevData,
      bank_id: selectedOption.value,
    }));
    setLoading(true);
    API({
      ...dmtIfscList,
      params: { bank: selectedOption.label },
      onSuccess: (res) => {
        const data = res.data.data;
        const ifscOptions = data.map((i) => ({
          value: i.ifsc,
          label: i.ifsc,
        }));
        setIfscList(ifscOptions);
        setLoading(false);
      },
      onError: (error) => {
        console.log("Error", error);
        setLoading(false);
      },
    });
  };
  const handleIfscSelectChange = (selectedOption) => {
    setBeneficiaryData((prevData) => ({
      ...prevData,
      ifsccode: selectedOption.value,
    }));
  };

  // banklist
  useEffect(() => {
    API({
      ...dmtBankList,
      onSuccess: (res) => {
        const data = res.data.data;
        const bankOptions = data.map((bank) => ({
          value: bank.bank_id,
          label: bank.bank_name,
        }));
        setBankList(bankOptions);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });
  }, []);

  // handle add beneficiary
  const handleAddBeneficiary = () => {
    setLoader(true);
    API({
      ...regNewBeneficiary,
      body: {
        ...beneficiaryData,
        mobile_number: mobile || "",
      },
      onSuccess: (res) => {
        setLoader(false);
        Swal.fire({
          title: `${res.data.data.message}`,
          customClass: {
            confirmButton: "swal-confirm-button",
            cancelButton: "swal-cancel-button",
          },
        }).then(() => {
          window.location.reload();
        });
        setBeneficiaryData({
          beneficiary_name: "",
          bank_id: "",
          account_number: "",
          ifsccode: "",
          dob: "",
          address: "",
          pincode: "",
        });
        setShowModal(false);
      },
      onError: (error) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.data.message}`,
        });
      },
    });
  };

  // fetch beneficiary
  useEffect(() => {
    API({
      ...fetchBeneficiary,
      body: { mobile_number: mobile || "" },
      onSuccess: (res) => {
        setBeneficiaryDetails(res.data.data.data);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });
  }, [mobile]);

  // delete beneficiary
  const handleDeleteBeneficiary = (bene_id) => {
    Swal.fire({
      title: "Are You Sure You Want To Delete Beneficiary? ",
      showCancelButton: true,
      confirmButtonColor: "#194280",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      customClass: {
        title: "swal-title",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedBeneficiaries = beneficiaryDetails.filter(
          (beneficiary) => beneficiary.bene_id !== bene_id
        );
        setBeneficiaryDetails(updatedBeneficiaries);

        API({
          ...deleteBeneficiary,
          body: {
            mobile_number: mobile,
            beneficiary_id: bene_id,
          },
          onSuccess: (res) => {
            Swal.fire({
              title: `${res.data.data.message}`,
              customClass: {
                confirmButton: "swal-confirm-button",
              },
            });
          },
          onError: (error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.data.message}`,
            });
          },
        });
      }
    });
  };

  // verify beneficiary (penny-drop)
  const handleVerifyBeneficiary = (beneficiary) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to verify this beneficiary?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, verify it!",
      cancelButtonText: "Cancel",
      customClass: {
        title: "swal-title",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const upinValue = upin.join("");
        const data = {
          mobile_number: mobile || "",
          account_number: selectedBeneficiary?.accno,
          bank_id: selectedBeneficiary.bankid,
          beneficiary_name: selectedBeneficiary?.name,
          pincode: selectedBeneficiary.pincode
            ? selectedBeneficiary.pincode
            : "",
          address: selectedBeneficiary.address
            ? selectedBeneficiary.address
            : "",
          dob: selectedBeneficiary.dob ? selectedBeneficiary.dob : "",
          beneficiary_id: selectedBeneficiary?.bene_id,
          upin: upinValue,
        };
        setLoader(true);
        API({
          ...dmtPennyDrop,
          body: data,
          onSuccess: (res) => {
            setLoader(false);

            Swal.fire({
              title: "Beneficiary Verified Successfully",
            }).then(() => {
              window.location.reload();
              setShowPennyDrop(false);
            });
          },
          onError: (error) => {
            setLoader(false);

            setShowPennyDrop(false);
            setUpin(["", "", "", "", "", ""]);
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          },
        });
      }
    });
  };

  // validation
  useEffect(() => {
    const validateFields = () => {
      return (
        beneficiaryData.beneficiary_name &&
        beneficiaryData.bank_id &&
        beneficiaryData.account_number &&
        beneficiaryData.ifsccode &&
        beneficiaryData.dob &&
        beneficiaryData.address &&
        beneficiaryData.pincode
      );
    };

    setIsButtonDisabled(!validateFields());
  }, [beneficiaryData]);

  // dmt transaction summary
  useEffect(() => {
    API({
      ...transSummmary,
      params: { mobile_number: mobile || "" },
      onSuccess: (res) => {
        setTransDetails(res.data.data);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });
  }, [mobile]);

  // change page
  const changePageFunc = (page) => {
    dispatch(changePage({ page }));
    navigate("/");
  };

  // date validation
  const todayDate = new Date();
  const eighteenYearsAgo = new Date(
    todayDate.getFullYear() - 18,
    todayDate.getMonth(),
    todayDate.getDate()
  )
    .toISOString()
    .split("T")[0];

  // handle dmt otp
  const handleTransactOtp = () => {
    Swal.fire({
      title: "Confirm Fund Transfer",
      text: "Are you sure you want to proceed with the transfer?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        title: "swal-title",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        const upinNumber = upin.join("");
        const requestData = {
          mobile_number: mobile,
          account_number: selectedBeneficiary?.accno,
          beneficiary_name: selectedBeneficiary?.name,
          beneficiary_id: selectedBeneficiary?.bene_id,
          amount: amount,
          bank_name: selectedBeneficiary.bankname,
          bank_id: selectedBeneficiary.bankid,
          address: selectedBeneficiary.address || "",
          dob: selectedBeneficiary.dob || "",
          pincode: selectedBeneficiary.pincode || "",
          upin: upinNumber,
        };

        API({
          ...dmtTransactOtp,
          body: { ...requestData },
          onSuccess: (res) => {
            setLoader(false);
            const receivedStateresp = res?.data?.data?.stateresp;
            const receivedRefId = res?.data?.data?.reference_id;

            if (receivedStateresp && receivedRefId) {
              setStateresp(receivedStateresp);
              setRefId(receivedRefId);

              Swal.fire({
                title: "Enter OTP",
                input: "password",
                inputPlaceholder: "Enter OTP received",
                showCancelButton: true,
                confirmButtonText: "Submit",
                preConfirm: (otp) => {
                  if (!otp) {
                    Swal.showValidationMessage("Please enter the OTP.");
                  }
                  return otp;
                },
              }).then((otpResult) => {
                if (otpResult.isConfirmed) {
                  setLoader(true);
                  const requestData = {
                    mobile_number: mobile,
                    account_number: selectedBeneficiary?.accno,
                    beneficiary_name: selectedBeneficiary?.name,
                    beneficiary_id: selectedBeneficiary?.bene_id,
                    amount: amount,
                    bank_name: selectedBeneficiary.bankname,
                    bank_id: selectedBeneficiary.bankid,
                    address: selectedBeneficiary.address || "",
                    dob: selectedBeneficiary.dob || "",
                    pincode: selectedBeneficiary.pincode || "",
                    otp: otpResult.value,
                    stateresp: receivedStateresp,
                    reference_id: receivedRefId,
                  };

                  API({
                    ...dmtTransact,
                    body: requestData,
                    onSuccess: (res) => {
                      setLoader(false);
                      Swal.fire({
                        icon: "success",
                        title: "Transaction Successful",
                      }).then(() => {
                        setShowTransfer(false);
                        window.location.reload();
                      });
                    },
                    onError: (error) => {
                      setLoader(false);
                      Swal.fire({
                        icon: "error",
                        title: `${error.response.data.message}`,
                      });
                    },
                  });
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Unable to proceed. Missing required data.",
              });
            }
          },
          onError: (error) => {
            setLoader(false);
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          },
        });
      }
    });
  };

  const openModalWithBeneficiary = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setShowFundModal(true);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);

    if (value === "" || (parseFloat(value) > 99 && parseFloat(value) <= 5000)) {
      setAmountError("");
    } else {
      setAmountError(
        "Amount must be greater than 99 and less than or equal to 5000"
      );
    }
  };

  const handleUpinChange = (index, value, event) => {
    value = value.replace(/[^0-9]/g, "");

    const updatedPin = [...upin];
    updatedPin[index] = value.charAt(0);

    if (inputRefs.current[index]) {
      inputRefs.current[index].value = value.charAt(0);
    }

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    } else if (event.key === "Backspace" && index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }

    setUpin(updatedPin);
  };
  const filteredTransactions = transDetails.filter(
    (t) =>
      t.beneficiary_name
        ?.toLowerCase()
        .includes(searchTermTrans.toLowerCase()) ||
      t.utr?.toLowerCase().includes(searchTermTrans.toLowerCase()) ||
      t.account_number?.toLowerCase().includes(searchTermTrans.toLowerCase())
  );

  const handleRefund = (trans) => {
    API({
      ...dmtRefundOtp,
      body: {
        reference_id: trans.reference_id,
        ackno: trans.ackno,
      },
      onSuccess: (res) => {
        const otp = res.data.otp;

        Swal.fire({
          title: "Confirm OTP",
          html: `Your OTP is <strong>${otp}</strong>`,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return API({
              ...dmtRefund,
              body: {
                reference_id: trans.reference_id,
                ackno: trans.ackno,
                otp: otp,
              },
              onSuccess: (refundRes) => {
                Swal.fire({
                  icon: "success",
                  title: `${refundRes.data.message}`,
                });
              },
              onError: (error) => {
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          },
        });
      },
      onError: (error) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleRefresh = (trans) => {
    setRotatingIds((prev) => [...prev, trans.reference_id]); // Start rotation only for this transaction

    API({
      ...dmtStatus,
      body: {
        reference_id: trans.reference_id,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });

    setTimeout(() => {
      setRotatingIds((prev) => prev.filter((id) => id !== trans.reference_id)); // Stop rotation only for this transaction
    }, 1000);
  };

  return (
    <>
      {/* Header  */}
      <div
        style={{ backgroundColor: "#D9D9D9" }}
        className="px-2 py-2 d-flex justify-content-between"
      >
        <img
          onClick={() => changePageFunc("home")}
          style={{
            width: "220px",
            cursor: "pointer",
            marginLeft: "20px",
            padding: "6px 0",
            pointerEvents: "all",
          }}
          src={newLogo}
          alt="logo"
        />
        <Header
          style={{
            width: "75%",
            backgroundColor: "#194280",
            borderRadius: "10px",
            color: "#194280",
            position: "sticky",
            padding: "10px 0px",
            top: "0",
          }}
        />
      </div>
      <div className="mb-2 mt-1 mx-5 px-2 d-flex align-items-center">
        <div
          className="mb-0 px-2 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "5px",
            color: "white",
            height: "50px",
            cursor: "pointer",
            pointerEvents: "all",
          }}
          onClick={() => changePageFunc("queryRemitter")}
        >
          <img src={serviceBackArrow} alt="back" />
        </div>
        <div className="d-flex ms-2 flex-wrap text-center justify-content-center">
          <div
            style={{ cursor: "pointer", margin: "0 20px" }}
            className="d-flex flex-column text-center position-relative "
          >
            <img
              style={{ width: "40px", margin: "6px auto 0 auto" }}
              src={bankTransferIcon}
              alt="icon"
            />
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
              }}
              className="mb-0 login_text selected_service"
            >
              Bank Transfer
            </p>
          </div>
        </div>
      </div>

      {loader ? (
        <img
          className="position-absolute"
          style={{
            height: "70px",
            width: "70px",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={loaderGif}
          alt="Loading..."
        />
      ) : (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #D9D9D9",
            boxShadow: "0px 4px 40px 10px #00000026",
            borderRadius: "20px",
          }}
          className="mx-5 p-2 position-relative"
        >
          <img
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              width: "100px",
            }}
            src={serviceCircle}
            alt="circle"
          />
          <div className="row d-flex">
            <div
              style={{ borderRight: "1px solid #D9D9D9" }}
              className="col-3 pt-4"
            >
              <div
                className="px-4 position-relative pb-5"
                style={{
                  backgroundColor: "rgba(25, 66, 128, 1)",
                  borderRadius: "20px",
                }}
              >
                <p
                  style={{
                    color: "#ffff",
                    fontSize: "20px",
                  }}
                  className="mb-1 ms-3 pt-3"
                >
                  Remitter Details
                </p>
                <div className="row mx-2">
                  <>
                    {/* <label
                      htmlFor="fname"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        marginTop: "8px",
                        paddingLeft: "0",
                      }}
                      className="login_text mt-3 ms-2"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      value={responseData?.data?.fname || ""}
                      placeholder="First Name"
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        borderRadius: "0px",
                        borderBottom: "1.5px solid white",
                      }}
                      autoComplete="off"
                      className="login_text"
                      readOnly
                    /> */}
                    {/* <label
                      htmlFor="lname"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        marginTop: "8px",
                        paddingLeft: "0",
                      }}
                      className="login_text mt-3 ms-2"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      value={responseData?.data?.lname || ""}
                      placeholder="Last Name"
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        borderBottom: "1.5px solid white",
                        borderRadius: "0px",
                      }}
                      autoComplete="off"
                      className="login_text"
                      readOnly
                    /> */}
                    <label
                      htmlFor="mobile"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        marginTop: "8px",
                        paddingLeft: "0",
                      }}
                      className="login_text mt-3 ms-2"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      value={mobile || ""}
                      placeholder="Mobile Number"
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        borderRadius: "0px",
                        borderBottom: "1.5px solid white",
                        width: "100%",
                      }}
                      autoComplete="off"
                      className="login_text"
                      readOnly
                    />
                  </>
                </div>
              </div>

              <div className="text-center mt-3">
                <button
                  className="px-4 py-2 col-10 login_text text-white"
                  style={{
                    backgroundColor: "#194280",
                    borderRadius: "8px",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  Add Beneficiary
                </button>
              </div>
            </div>

            {/* fund transfer  */}
            {showTransfer || showPennyDrop ? (
              <div className="col-9 text-center mt-5">
                <p
                  className="mb-2"
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {selectedBeneficiary.name}
                </p>
                <p
                  className="mb-2"
                  style={{
                    fontSize: "14px",
                    color: "#696666",
                    fontFamily: "Montserrat",
                  }}
                >
                  Enter your UPIN to Pay
                </p>
                <div className="d-flex align-items-center justify-content-center mb-2 col-1 mx-auto">
                  <span
                    className="text-center"
                    style={{
                      fontSize: "20px",
                      color: "#696666",
                      fontWeight: "500",
                      marginRight: "6px",
                    }}
                  >
                    ₹
                  </span>
                  <input
                    type="number"
                    className="text-center"
                    value={showPennyDrop ? "2" : amount}
                    onChange={handleAmountChange}
                    placeholder="Amount"
                    style={{
                      color: "#696666",
                      fontWeight: "500",
                      fontSize: "17px",
                      width: "100%",
                    }}
                    readOnly={showPennyDrop ? true : false}
                  />
                  {amountError && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "50px",
                        position: "absolute",
                      }}
                    >
                      {amountError}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <input
                      key={index}
                      type="password"
                      maxLength={1}
                      className="mx-1"
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) =>
                        handleUpinChange(index, e.target.value, e)
                      }
                      onKeyDown={(e) =>
                        handleUpinChange(index, e.target.value, e)
                      }
                      value={upin[index] || ""}
                      style={{
                        width: "40px",
                        height: "40px",
                        textAlign: "center",
                        fontSize: "20px",
                        borderBottom: "2px solid black",
                        borderRadius: "0px",
                        margin: "0 4px",
                      }}
                    />
                  ))}
                </div>
                <p
                  className="my-3 mx-auto"
                  style={{
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    width: "260px",
                  }}
                >
                  For your security, please enter your 6-digit security code you
                  created at the time of registration.
                </p>
                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#FFA201",
                      borderColor: "#FFA201",
                      borderRadius: "5px",
                      width: "170px",
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      opacity:
                        showPennyDrop || (amount > 99 && amount <= 5000)
                          ? "1"
                          : "0.5",
                      cursor:
                        showPennyDrop || (amount > 99 && amount <= 5000)
                          ? "pointer"
                          : "not-allowed",
                    }}
                    disabled={
                      !showPennyDrop && !(amount > 99 && amount <= 5000)
                    }
                    onClick={
                      showPennyDrop
                        ? handleVerifyBeneficiary
                        : handleTransactOtp
                    }
                  >
                    {loader ? (
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={loaderGif}
                        alt="loading"
                      />
                    ) : showPennyDrop ? (
                      "Pay ₹ 2"
                    ) : (
                      "Pay ₹" + amount
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <>
                {/* beneficiary details  */}
                <div className="col-5 pe-0">
                  <div
                    className="text-center py-2"
                    style={{
                      borderBottom: "1.5px solid black",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    Beneficiary Details
                  </div>
                  <input
                    type="search"
                    placeholder="Search Beneficiary"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                      width: "90%",
                      padding: "10px",
                      borderBottom: "1px solid #D9D9D9",
                      marginBottom: "10px",
                    }}
                    className="ms-3"
                  />
                  <div
                    style={{
                      height: "280px",
                      overflowY: "auto",
                      borderRight: "1px solid #D9D9D9",
                    }}
                  >
                    {filteredBeneficiaries?.length > 0 ? (
                      filteredBeneficiaries?.map((beneficiary) => (
                        <div
                          className="px-3 mx-3 mb-4"
                          key={beneficiary.bene_id}
                          style={{
                            borderBottom: "1px solid black",
                            padding: "16px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#f9f9f9",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className="mb-0 mt-2"
                                style={{
                                  color: "#696666",
                                }}
                              >
                                Bank Name{" "}
                              </p>
                              <p
                                className="mt-2 mb-0"
                                style={{ fontWeight: "500", fontSize: "15px" }}
                              >
                                {beneficiary.bankname}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                              <p
                                className="mb-0"
                                style={{
                                  color: "#696666",
                                }}
                              >
                                Beneficiary Name{" "}
                              </p>
                              <p
                                className="mb-0"
                                style={{ fontWeight: "500", fontSize: "15px" }}
                              >
                                {beneficiary.name}{" "}
                                {beneficiary.verified === "1" && (
                                  <FcApproval style={{ marginTop: "-3px" }} />
                                )}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                              <p
                                className="mb-0"
                                style={{
                                  color: "#696666",
                                }}
                              >
                                Account Number{" "}
                              </p>
                              <p
                                className="mb-0"
                                style={{ fontWeight: "500", fontSize: "15px" }}
                              >
                                {beneficiary.accno}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                              <p
                                className="mb-0"
                                style={{
                                  color: "#696666",
                                }}
                              >
                                IFSC Code{" "}
                              </p>
                              <p
                                className="mb-0"
                                style={{ fontWeight: "500", fontSize: "15px" }}
                              >
                                {beneficiary.ifsc}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-3">
                              {beneficiary.verified === "0" ? (
                                <button
                                  className="px-3 py-1"
                                  onClick={() => {
                                    setShowPennyDrop(true);
                                    setSelectedBeneficiary(beneficiary);
                                  }}
                                  style={{
                                    color: "white",
                                    backgroundColor: "#023685",
                                    borderRadius: "5px",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  Validate
                                </button>
                              ) : (
                                <img
                                  style={{
                                    cursor: "pointer",
                                    pointerEvents: "all",
                                  }}
                                  src={fundTransferBenef}
                                  alt="fund-transfer"
                                  onClick={() =>
                                    openModalWithBeneficiary(beneficiary)
                                  }
                                />
                              )}
                              <p
                                className="mb-0"
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  fontFamily: "Montserrat",
                                  fontSize: "15px",
                                }}
                                onClick={() =>
                                  handleDeleteBeneficiary(beneficiary.bene_id)
                                }
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <p
                          className="mb-0 text-center"
                          style={{ fontWeight: "500" }}
                        >
                          No beneficiary found
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {/* transaction summary  */}
                <div className="col-4 ps-0">
                  <div
                    className="text-center py-2"
                    style={{
                      borderBottom: "1.5px solid black",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    Transaction Summary
                  </div>
                  <input
                    type="search"
                    placeholder="Search Transaction Summary"
                    value={searchTermTrans}
                    onChange={(e) => setSearchTermTrans(e.target.value)}
                    style={{
                      width: "90%",
                      padding: "10px",
                      borderBottom: "1px solid #D9D9D9",
                      marginBottom: "10px",
                    }}
                    className="ms-3"
                  />
                  <div
                    style={{
                      height: "280px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredTransactions.length > 0 ? (
                      filteredTransactions.map((t, index) => (
                        <div
                          className="px-3 mx-3 mb-4"
                          key={index}
                          style={{
                            borderBottom: "1px solid black",
                            padding: "16px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#f9f9f9",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            <p
                              style={{ fontSize: "13px", color: "#696666" }}
                              className="mb-0 text-center"
                            >
                              UTR No ID: {t.utr ? t.utr : "Not found"}
                            </p>
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                style={{ fontSize: "14px" }}
                                className="mb-0 mt-2"
                              >
                                Beneficiary Name{" "}
                              </p>
                              <p
                                className="mt-2 mb-0"
                                style={{ fontWeight: "500", fontSize: "13px" }}
                              >
                                {t.beneficiary_name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                              <p style={{ fontSize: "14px" }} className="mb-0">
                                Account Number{" "}
                              </p>
                              <p
                                className="mb-0"
                                style={{ fontWeight: "500", fontSize: "13px" }}
                              >
                                {t.account_number}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <div className="d-flex align-items-center">
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Payment Transaction:
                                </p>

                                {/* Icons based on status */}
                                {t.status === "Transaction Successfull" && (
                                  <FaCheckCircle
                                    style={{
                                      color: "green",
                                      marginLeft: "8px",
                                      marginTop: "-4px",
                                    }}
                                  />
                                )}
                                {t.status === "Failed and Refunded" && (
                                  <>
                                    <FaTimesCircle
                                      style={{
                                        color: "red",
                                        marginLeft: "4px",
                                        marginTop: "-4px",
                                        fontSize: "12px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "4px",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Refunded
                                    </span>
                                  </>
                                )}

                                {t.status === "Transaction In Process" && (
                                  <FaSpinner
                                    className="spin"
                                    style={{
                                      color: "orange",
                                      marginLeft: "8px",
                                      marginTop: "-4px",
                                    }}
                                  />
                                )}

                                {t.status === "Transaction Failed" && (
                                  <FaTimesCircle
                                    style={{
                                      color: "red",
                                      marginLeft: "8px",
                                      marginTop: "-4px",
                                    }}
                                  />
                                )}

                                {t.status === "Transaction on Hold" && (
                                  <>
                                    <FaClock
                                      style={{
                                        color: "orange",
                                        marginLeft: "4px",
                                        fontSize: "12px",
                                      }}
                                    />
                                  </>
                                )}

                                {/* Refresh Icon Condition */}
                                {[
                                  "Transaction In Process",
                                  "Transaction Sent To Bank",
                                  "Transaction on Hold",
                                  "",
                                ].includes(t.status) && (
                                  <FiRefreshCcw
                                    className={
                                      rotatingIds.includes(t.reference_id)
                                        ? "rotating"
                                        : ""
                                    }
                                    style={{
                                      fontSize: "15px",
                                      marginLeft: "8px",
                                      marginTop: "-4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleRefresh(t)}
                                  />
                                )}

                                {![
                                  "Transaction Successfull",
                                  "Transaction In Process",
                                  "Transaction Failed",
                                  "Transaction Sent To Bank",
                                  "Transaction on Hold",
                                  "Failed and Refunded",
                                ].includes(t.status) && (
                                  <p
                                    className="mb-0 ms-1"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {t.status}
                                  </p>
                                )}
                              </div>

                              {/* Transaction Amount */}
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Transaction Amount
                              </p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <p
                                className="mb-0"
                                style={{
                                  color: "#696666",
                                  fontSize: "13px",
                                }}
                              >
                                {t.transaction_date}
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                ₹ {t.amount}
                              </p>
                            </div>
                          </div>
                          {t.status === "Transaction Failed" && (
                            <div className="text-center">
                              <button
                                onClick={() => {
                                  handleRefund(t);
                                }}
                                className="px-3 py-1"
                                style={{
                                  border: "1px solid #023685",
                                  borderRadius: "10px",
                                  color: "#023685",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                Refund
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <p
                          className="mb-0 text-center"
                          style={{ fontWeight: "500" }}
                        >
                          No transactions found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Add Beneficiary"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "750px",
            padding: "20px",
          },
        }}
      >
        <h2
          className="text-center mb-5"
          style={{ fontSize: "18px", fontFamily: "Montserrat" }}
        >
          Add Beneficiary Detail{" "}
        </h2>
        <div className="px-4">
          <div className="row">
            <div className="col-6">
              <label
                style={{ fontSize: "13px", fontFamily: "Montserrat" }}
                htmlFor="beneficiary_name"
              >
                Beneficiary Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="beneficiary_name"
                name="beneficiary_name"
                value={beneficiaryData.beneficiary_name}
                style={{
                  borderBottom: "1px solid #ADACAC",
                  borderRadius: "0px",
                  fontFamily: "Montserrat",
                }}
                onChange={handleInputChange}
                className="mb-3 w-100"
                autoComplete="off"
              />
            </div>
            <div className="col-6">
              <label
                style={{ fontSize: "13px", fontFamily: "Montserrat" }}
                htmlFor="mobile_number"
              >
                Mobile Number <span style={{ color: "red" }}>*</span>
              </label>

              <input
                type="number"
                id="mobile_number"
                name="mobile_number"
                style={{
                  fontFamily: "Montserrat",
                  borderBottom: "1px solid #ADACAC",
                  borderRadius: "0px",
                }}
                value={mobile || ""}
                placeholder="Mobile Number"
                className="mb-1 w-100"
                readOnly
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <label
                style={{
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
                htmlFor="bank"
              >
                Select Bank <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                id="bank"
                name="bank"
                value={bankList.find(
                  (option) => option.value === beneficiaryData.bank_id
                )}
                options={bankList}
                placeholder="Select Bank"
                onChange={handleSelectChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent",
                    borderWidth: "0 0 1.5px 0",
                    width: "100%",
                    borderRadius: "0px",
                    fontSize: "14px",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                  }),
                }}
                className="dmt_select mb-3"
              />
            </div>

            <div style={{ marginTop: "10px" }} className="col-6">
              <label
                style={{
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
                htmlFor="account_number"
              >
                Account Number <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                id="account_number"
                name="account_number"
                value={beneficiaryData.account_number}
                style={{
                  fontFamily: "Montserrat",
                  borderBottom: "1px solid #ADACAC",
                  borderRadius: "0px",
                  marginTop: "6px",
                }}
                onChange={handleInputChange}
                className="mb-3 w-100"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6">
              <label
                style={{
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
                htmlFor="ifsccode"
              >
                Select IFSC<span style={{ color: "red" }}>*</span>
              </label>
              <Select
                id="ifsccode"
                name="ifsccode"
                value={ifscList.find(
                  (option) => option.value === beneficiaryData?.value
                )}
                options={ifscList}
                placeholder={loading ? "Loading IFSC codes..." : "Select IFSC"}
                onChange={handleIfscSelectChange}
                isDisabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent",
                    borderWidth: "0 0 1.5px 0",
                    width: "100%",
                    borderRadius: "0px",
                    fontSize: "14px",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                  }),
                }}
                className="dmt_select mb-3"
              />
            </div>
            <div style={{ marginTop: "8px" }} className="col-6">
              <label
                style={{
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
                htmlFor="dob"
              >
                Date of Birth
              </label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={beneficiaryData.dob}
                placeholder="Date of Birth"
                style={{
                  fontFamily: "Montserrat",
                  borderBottom: "1px solid #ADACAC",
                  borderRadius: "0px",
                  marginTop: "8px",
                }}
                onChange={handleInputChange}
                className="mb-3 w-100"
                max={eighteenYearsAgo}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6">
              <label
                style={{
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
                htmlFor="address"
              >
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={beneficiaryData.address}
                style={{
                  fontFamily: "Montserrat",
                  borderBottom: "1px solid #ADACAC",
                  borderRadius: "0px",
                }}
                onChange={handleInputChange}
                className="mb-3 w-100"
                autoComplete="off"
              />
            </div>
            <div className="col-6">
              <label
                style={{
                  fontSize: "13px",
                  fontFamily: "Montserrat",
                }}
                htmlFor="pincode"
              >
                Pincode
              </label>
              <input
                type="number"
                id="pincode"
                name="pincode"
                value={beneficiaryData.pincode}
                style={{
                  fontFamily: "Montserrat",
                  borderBottom: "1px solid #ADACAC",
                  borderRadius: "0px",
                }}
                onChange={handleInputChange}
                className="mb-3 w-100"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="px-4 py-2 login_text mt-4 text-white"
              style={{
                backgroundColor: "#023685",
                borderRadius: "8px",
                border: "none",
                fontSize: "15px",
                opacity: isButtonDisabled || loading ? 0.5 : 1,
                cursor: isButtonDisabled || loading ? "not-allowed" : "pointer",
              }}
              onClick={handleAddBeneficiary}
              disabled={isButtonDisabled || loading}
            >
              {loader ? "Loading..." : "Add Beneficiary"}
            </button>
          </div>
        </div>
      </Modal>

      {/* fund transfer  */}
      <Modal
        isOpen={showFundModal}
        onRequestClose={() => setShowFundModal(false)}
        contentLabel="Fund Transfer Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "500px",
            backgroundColor: "white",
            padding: "20px",
          },
        }}
      >
        <h2
          className="text-center mb-4"
          style={{ fontSize: "18px", fontFamily: "Montserrat" }}
        >
          Bank Transfer Details{" "}
        </h2>
        <div className="px-4">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p
                className="mb-0 mt-2"
                style={{
                  color: "#696666",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
              >
                Bank Name{" "}
              </p>
              <p
                className="mt-2 mb-0"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                {selectedBeneficiary?.bankname}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <p
                className="mb-0 mt-2"
                style={{
                  color: "#696666",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
              >
                Beneficiary Name{" "}
              </p>
              <p
                className="mt-2 mb-0"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                {selectedBeneficiary?.name}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <p
                className="mb-0 mt-2"
                style={{
                  color: "#696666",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
              >
                Account Number{" "}
              </p>
              <p
                className="mt-2 mb-0"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                {selectedBeneficiary?.accno}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <p
                className="mb-0 mt-2"
                style={{
                  color: "#696666",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
              >
                IFSC Code{" "}
              </p>
              <p
                className="mt-2 mb-0"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                {selectedBeneficiary?.ifsc}
              </p>
            </div>
          </div>

          {/* submit button */}
          <div className="text-center mt-4">
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: "#FFA201",
                borderColor: "#FFA201",
                borderRadius: "5px",
                width: "170px",
                fontSize: "15px",
                fontFamily: "Montserrat",
              }}
              onClick={() => {
                setShowTransfer(true);
                setShowFundModal(false);
              }}
            >
              {loader ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={loaderGif}
                  alt="loading"
                />
              ) : (
                "Fund Transfer"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddBeneficiary;
