import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { bookTicket } from "../../../../urls";
import API from "../../../../components/api2";
import tripRender from "../../../../gif/busSearch.gif";
import ViewTicket from "./ViewTicket";
import { IoMdArrowRoundBack } from "react-icons/io";

const BookTicket = ({
  selectedSeatsDetails,
  responseData,
  passengerDetails,
  setIsSuccess,
}) => {
  const [upin, setUpin] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [showViewTicket, setShowViewTicket] = useState(false);
  const [data, setData] = useState({});
  const inputRefs = useRef([]);

  const handleUpinChange = (index, value, event) => {
    value = value.replace(/[^0-9]/g, "");

    const updatedPin = [...upin];
    updatedPin[index] = value.charAt(0);

    if (inputRefs.current[index]) {
      inputRefs.current[index].value = value.charAt(0);
    }

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    } else if (event.key === "Backspace" && index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }

    setUpin(updatedPin);
  };

  const totalFare = selectedSeatsDetails?.reduce(
    (total, seat) => total + parseFloat(seat.fare || 0),
    0
  );
  const totalBaseFare = selectedSeatsDetails?.reduce(
    (total, seat) => total + parseFloat(seat.baseFare || 0),
    0
  );

  const handlePay = () => {
    const pin = upin.join("");
    const baseFare = totalBaseFare;
    const amount = totalFare;
    const blockKey = responseData.blockKey;
    const passengerPhone = passengerDetails?.[0]?.mobile;
    const passengerEmail = passengerDetails?.[0]?.email;
    const requestBody = {
      base_fare: baseFare,
      amount: amount,
      blockKey: blockKey,
      passenger_phone: passengerPhone,
      passenger_email: passengerEmail,
      upin: pin,
    };

    setLoading(true);

    API({
      ...bookTicket,
      body: requestBody,
      onSuccess: (res) => {
        setLoading(false);
        setData(res?.data?.data);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          setShowViewTicket(true);
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <img style={{ width: "40%" }} src={tripRender} alt="Loading" />
      </div>
    );
  }

  if (showViewTicket) {
    return <ViewTicket setShowViewTicket={setShowViewTicket} data={data} />;
  }

  return (
    <div className="row justify-content-center mt-4">
      <div
        style={{ cursor: "pointer" }}
        className="d-flex align-items-center mb-4"
        onClick={() => setIsSuccess(false)}
      >
        <IoMdArrowRoundBack />
        <p className="mb-0 ms-1 fw-bold">Back</p>
      </div>
      <div className="col-4">
        <div style={{ border: "1px solid #DADADA", padding: "10px" }}>
          <h3
            className="fw-bold"
            style={{ color: "#063E70", marginBottom: "5px", fontSize: "18px" }}
          >
            {responseData.bus_name} | Seats- {responseData.seat_number}
          </h3>
          <p style={{ fontSize: "14px", margin: "5px 0", color: "#696666" }}>
            {responseData.bus_type}
          </p>
          <p
            className="fw-bold"
            style={{ fontSize: "14px", margin: "5px 0", color: "#063E70" }}
          >
            Departure
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: "14px", margin: "5px 0" }}>
              {new Date(responseData.booking_date).toLocaleDateString("en-GB")}
            </p>
            <p style={{ fontSize: "14px", margin: "5px 0" }}>
              {responseData.booking_time}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid #DADADA",
              margin: "10px 0",
              paddingTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <div>
              <strong style={{ fontSize: "16px" }}>Boarding Point</strong>
              <p style={{ margin: "5px 0", color: "#50555C" }}>
                {responseData.source}
              </p>
            </div>
            <div>
              <strong style={{ fontSize: "16px" }}>Dropping Point</strong>
              <p style={{ margin: "5px 0", color: "#50555C" }}>
                {responseData.destination}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-7 text-center">
        <p
          className="mb-2"
          style={{
            fontSize: "14px",
            color: "#696666",
            fontFamily: "Montserrat",
          }}
        >
          Enter your UPIN to Pay
        </p>
        <div className="d-flex align-items-center justify-content-center mb-2 mx-auto">
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="text-center"
              style={{
                fontSize: "20px",
                color: "#696666",
                fontWeight: "500",
                marginRight: "6px",
              }}
            >
              ₹
            </span>
            <input
              type="number"
              className="text-center"
              value={totalFare || ""}
              placeholder="Amount"
              style={{
                color: "#696666",
                fontWeight: "500",
                fontSize: "17px",
                width: "30%",
              }}
              readOnly
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {Array.from({ length: 6 }).map((_, index) => (
            <input
              key={index}
              type="password"
              maxLength={1}
              className="mx-1"
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleUpinChange(index, e.target.value, e)}
              onKeyDown={(e) => handleUpinChange(index, e.target.value, e)}
              value={upin[index] || ""}
              style={{
                width: "40px",
                height: "40px",
                textAlign: "center",
                fontSize: "20px",
                borderBottom: "2px solid black",
                borderRadius: "0px",
                margin: "0 4px",
              }}
            />
          ))}
        </div>
        <p
          className="my-3 mx-auto"
          style={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            width: "260px",
          }}
        >
          For your security, please enter your 6-digit security code you created
          at the time of registration.
        </p>
        <div className="text-center mt-4">
          <button
            className="btn btn-primary"
            style={{
              backgroundColor: "#FFA201",
              borderColor: "#FFA201",
              borderRadius: "5px",
              width: "170px",
              fontSize: "15px",
              fontFamily: "Montserrat",
            }}
            onClick={handlePay}
          >
            Pay ₹ {totalFare || ""}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookTicket;
