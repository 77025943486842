import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import {
  aadharPayIcon,
  aadharSeedingIcon,
  accOpeningIcon,
  authIcon,
  balInquiryIcon,
  bankTransferIcon,
  broadBandPostpaid,
  busIcon,
  cableTv,
  clubsAssociation,
  creditCard,
  currentIcon,
  dthBillIcon,
  dthRechargeIcon,
  eduFees,
  elecBillIcon,
  fastag,
  fastagRechargeIcon,
  flightIcon,
  gasIcon,
  hospitalPathology,
  hotelIcon,
  housingSociety,
  insuranceIcon,
  irctcIcon,
  landlinePostpaid,
  miniStIcon,
  mobRechg,
  mobSeedingIcon,
  mobileRechargeIcon,
  municipalTax,
  ncmc,
  nps,
  pipeBill,
  rdIcon,
  recurringDeposit,
  rent,
  savingIcon,
  scanIcon,
  subsFees,
  taxIcon,
  teamDepIcon,
  telecomIcon,
  uniqueIcon,
  waterBillIcon,
  withdrawlIcon,
} from "../images";
import {
  changePage,
  setscrollToHighlight,
} from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { BsBank } from "react-icons/bs";

const Services = ({ menusList, isRegEnabled }) => {
  const selectedServiceId = useSelector((state) => state.dashboard.scrollToId);
  const selectedServiceIdHighlight = useSelector(
    (state) => state.dashboard.setscrollToHighlight
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const services = [
    { name: "Bank Correspondence Services", id: "bankCorrespondenceService" },
    {
      name: "Aadhar Enabled Payment Services (AEPS)",
      id: "aadharEnablePayment",
    },
    { name: "Micro ATM", id: "microATM" },
    { name: "Domestic Money Transfer", id: "domesticMoneyTransfer" },
    { name: "Cash Management Services", id: "cashManagement" },
    { name: "Bharat Bill Payment Services", id: "bharatBillPayment" },
    { name: "Travel Services", id: "travelServices" },
    { name: "Financial Services", id: "financialServices" },
    { name: "Government Services", id: "governmentServices" },
    { name: "Recharge Services", id: "rechargeServices" },
    { name: "Device Sales", id: "devicesSales" },
  ];

  const subServices = {
    "Bank Correspondence Services": [
      { name: "Account Opening", img: accOpeningIcon, id: "" },
      { name: "Saving", img: savingIcon, id: "" },
      { name: "Current", img: currentIcon, id: "" },
      { name: "Recurring Deposit", img: rdIcon, id: "" },
      { name: "Term Deposit", img: teamDepIcon, id: "" },
      { name: "Aadhaar Seeding", img: aadharSeedingIcon, id: "" },
      { name: "Mobile Seeding", img: mobSeedingIcon, id: "" },
    ],
    "Aadhar Enabled Payment Services (AEPS)": [
      { name: "Withdrawal", img: withdrawlIcon, id: "aepsWithdrawal" },
      { name: "Mini Statement", img: miniStIcon, id: "aepsStatement" },
      { name: "Balance Enquiry", img: balInquiryIcon, id: "aepsBalance" },
      { name: "Aadhaar Pay", img: aadharPayIcon, id: "aepsAadharPay" },
    ],
    "Micro ATM": [
      { name: "Withdrawal", img: withdrawlIcon, id: "microWithdawal" },
      { name: "Balance Enquiry", img: balInquiryIcon, id: "microBalEnq" },
    ],
    "Domestic Money Transfer": [
      { name: "Bank Transfer", img: bankTransferIcon, id: "queryRemitter" },
    ],
    "Cash Management Services": [
      { name: "Pay Loan EMI", img: scanIcon, id: "" },
    ],
    "Bharat Bill Payment Services": [
      {
        name: "Mobile Postpaid",
        img: mobRechg,
        id: "mobileRechargeBbps",
      },
      { name: "Gas Bill", img: gasIcon, id: "bbpsGasBill" },
      { name: "Water Bill", img: waterBillIcon, id: "bbpsWaterBill" },
      { name: "DTH Bill", img: dthBillIcon, id: "bbpsDthBill" },
      { name: "Pipeline Gas Bill", img: pipeBill, id: "bbpsPipedGas" },
      { name: "FastTag Recharge", img: fastag, id: "bbpsFastag" },
      { name: "Education Fees", img: eduFees, id: "bbpsEduFees" },
      { name: "Housing Society", img: housingSociety, id: "bbpsHousing" },
      { name: "NCMC", img: ncmc, id: "bbpsNcmc" },
      { name: "Municipal Taxes", img: municipalTax, id: "bbpsMunTaxes" },
      { name: "Subscription Fees", img: subsFees, id: "bbpsSubFees" },
      { name: "NPS", img: nps, id: "bbpsNps" },
      { name: "Rent", img: rent, id: "bbpsRent" },
      { name: "Credit Cards", img: creditCard, id: "bbpsCreditCards" },
      {
        name: "Clubs & Associations",
        img: clubsAssociation,
        id: "bbpsClubAssoc",
      },
      {
        name: "Hospital & Pathology",
        img: hospitalPathology,
        id: "bbpsHospital",
      },
      {
        name: "Broadband Postpaid",
        img: broadBandPostpaid,
        id: "bbpsBroadband",
      },
      { name: "Recurring Deposit", img: recurringDeposit, id: "bbpsRd" },
      {
        name: "Landline Postpaid",
        img: landlinePostpaid,
        id: "bbpsLandlinePostpaid",
      },
      { name: "Cable TV", img: cableTv, id: "bbpsCableTv" },
    ],
    "Travel Services": [
      { name: "Hotel Booking", img: hotelIcon, id: "hotelBooking" },
      { name: "Flight Ticket Booking", img: flightIcon, id: "flightBooking" },
      { name: "Bus Ticket Booking", img: busIcon, id: "busBooking" },
      { name: "Train Ticket Booking", img: irctcIcon, id: "trainBooking" },
    ],
    "Financial Services": [
      { name: "Insurance Services", img: insuranceIcon, id: "" },
    ],
    "Government Services": [
      {
        name: "Pan Card",
        img: uniqueIcon,
        id: "panCard",
      },
      {
        name: "Government Scheme Application",
        img: taxIcon,
        id: "governmentSchemeApplication",
      },
    ],
    "Recharge Services": [
      {
        name: "Mobile Recharge",
        img: mobileRechargeIcon,
        id: "mobileRecharge",
      },
      { name: "DTH Recharge", img: dthRechargeIcon, id: "dthRecharge" },
      { name: "Fastag Recharge", img: fastagRechargeIcon, id: "" },
    ],
    "Device Sales": [
      { name: "Printer", img: authIcon, id: "printer" },
      { name: "FP Device", img: authIcon, id: "fpDevice" },
      { name: "MATM", img: authIcon, id: "matm" },
    ],
  };

  const filteredServices = services.filter((service) =>
    menusList.some(
      (menu) => service.name.toLowerCase() === menu.menu_name.toLowerCase()
    )
  );

  const serviceRefs = useRef({});
  const memoizedServiceRefs = useMemo(() => {
    return filteredServices.reduce((acc, service) => {
      acc[service.id] = createRef();
      return acc;
    }, {});
  }, [filteredServices]);

  serviceRefs.current = memoizedServiceRefs;

  useEffect(() => {
    if (selectedServiceId && serviceRefs.current[selectedServiceId]) {
      setTimeout(() => {
        serviceRefs.current[selectedServiceId].current.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }
  }, [selectedServiceId]);

  useEffect(() => {
    if (
      selectedServiceIdHighlight &&
      serviceRefs.current[selectedServiceIdHighlight]
    ) {
      setTimeout(() => {
        serviceRefs.current[selectedServiceIdHighlight].current.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }
  }, [selectedServiceIdHighlight]);

  // intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute("id");
            dispatch(setscrollToHighlight(id));
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(serviceRefs.current).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [dispatch, memoizedServiceRefs]);

  const changePageFunc = (subService) => {
    if (subService.name === "Bank Transfer") {
      dispatch(
        changePage({
          page: "queryRemitter",
        })
      );
      navigate("/");
    } else {
      dispatch(
        changePage({
          page: "serviceTabs",
          service: subService,
        })
      );
      navigate("/", { state: { service: subService, id: subService.id } });
    }
  };

  const handleRegister = () => {
    dispatch(changePage({ page: "onBoarding" }));
    navigate("/");
  };
  const handleConfigure = () => {
    dispatch(changePage({ page: "configureBanks" }));
    navigate("/");
  };

  return (
    <>
      <Header />

      <div
        className="py-3 my-2 overflow-y-auto"
        style={{ backgroundColor: "#194280" }}
      >
        {filteredServices.map((service, serviceIndex) => (
          <div
            className="px-4 pb-5"
            key={serviceIndex}
            ref={serviceRefs.current[service.id]}
            id={service.id}
            style={{
              backgroundColor:
                isRegEnabled === false &&
                service.name === "Aadhar Enabled Payment Services (AEPS)"
                  ? "#0C2140"
                  : service.name === "Government Services" ||
                    service.name === "Cash Management Services" ||
                    service.name === "Bharat Bill Payment Services" ||
                    service.name === "Travel Services" ||
                    service.name === "Financial Services"
                  ? "#E7E8E9"
                  : "#194280",
            }}
          >
            <h2
              className="mb-0"
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "20px",
                fontWeight: "600",
                fontFamily: "Montserrat",
                paddingTop: serviceIndex === 0 ? "20px" : "48px",
                color:
                  isRegEnabled === false &&
                  service.name === "Aadhar Enabled Payment Services (AEPS)"
                    ? "#7F7F7F"
                    : service.name === "Government Services" ||
                      service.name === "Cash Management Services" ||
                      service.name === "Bharat Bill Payment Services" ||
                      service.name === "Travel Services" ||
                      service.name === "Financial Services"
                    ? "#194280"
                    : "white",
              }}
            >
              {service.name}
            </h2>
            {service.name === "Aadhar Enabled Payment Services (AEPS)" &&
              isRegEnabled === false && (
                <div className="text-center">
                  <button
                    className="bg-white border-0 py-2 px-3"
                    onClick={handleRegister}
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "13px",
                      borderRadius: "10px",
                    }}
                  >
                    Registration
                  </button>
                </div>
              )}
            {service.name === "Aadhar Enabled Payment Services (AEPS)" &&
              isRegEnabled === true && (
                <div
                  style={{
                    maxWidth: "150px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleConfigure}
                  className="d-flex align-items-center justify-content-center bg-white mt-3 py-2 px-3 mx-auto"
                >
                  <button
                    className="border-0"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "13px",
                    }}
                  >
                    Configure
                  </button>
                  <BsBank className="ms-2" />
                </div>
              )}
            <div style={{ marginTop: "40px" }} className="row px-3">
              {subServices[service.name] &&
                subServices[service.name].map((subService, subServiceIndex) => (
                  <div
                    key={`${serviceIndex}-${subServiceIndex}`}
                    className="col-3 text-center mt-2 mb-2"
                    onClick={(e) => {
                      if (
                        service.name ===
                          "Aadhar Enabled Payment Services (AEPS)" &&
                        isRegEnabled === false
                      ) {
                        e.preventDefault();
                      } else {
                        changePageFunc(subService);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      opacity:
                        service.name ===
                          "Aadhar Enabled Payment Services (AEPS)" &&
                        isRegEnabled === false
                          ? 0.5
                          : 1,
                    }}
                  >
                    <img
                      className="mt-3"
                      src={subService.img}
                      alt={`img-${subServiceIndex + 1}`}
                    />
                    <p
                      style={{
                        fontSize: "15px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        color:
                          service.name === "Government Services" ||
                          service.name === "Cash Management Services" ||
                          service.name === "Bharat Bill Payment Services" ||
                          service.name === "Travel Services" ||
                          service.name === "Financial Services"
                            ? "#194280"
                            : "white",
                      }}
                      className="mb-0 mt-3"
                    >
                      {subService.name}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  menusList: state.user.menus,
  isRegEnabled: state.user.aepsReg,
});

export default connect(mapStateToProps)(Services);
